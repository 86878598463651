<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title class="mb-2">Клинеры
          <v-btn @click="newCleaner" class="ml-4 align-self-end">+ Добавить</v-btn>
          <v-btn @click="$refs.InterviewSMS.open();" class="ml-4 align-self-end">СМС о собеседовании</v-btn>
          <v-btn @click="toggleStats()" :style="statsOpened?'':'background: transparent !important'"
                 class="ml-4 align-self-end">Статистика
          </v-btn>
        </v-card-title>
        <v-card-text v-if="statsOpened">
          <YearMonth @change="toggleStats(true)" v-model="statsDate"></YearMonth>
          <v-data-table
              :headers="[
                  {text:'Источник', value:'source'},
                  {text:'Отклики', value:'responses'},
                  {text:'Введено в црм', value:'total'},
                  {text:'Назначено интервью', value:'interview_created'},
                  {text:'Пришло на интервью', value:'interview_came'},
                  {text:'Прошло интервью', value:'interview_came'},
                  {text:'Назначена стажировка', value:'became_student'},
                  {text:'Оформлены', value:'oform'},
                  {text:'Получили 2 зп', value:'two_months'},
                  // {text:'Уволено/Не работает', value:'fired'},
                  {text:'Выручка', value:'revenue'},
                  {text:'Расход за месяц', value: 'cons'},
              ]"
              :items="stats"
              :footer-props="{'items-per-page-options':[-1]}"
              class="stripped cursor-pointer"
              loading-text="Загрузка..."
              :search="search"
              mobile-breakpoint="0"
          >
            <template v-slot:item.index="{item, index}">
            </template>
            <template v-slot:item.cons="{item, index}">
              <SavingParam v-if="!['всего', '---'].includes(item.source.toLowerCase())"
                           :name="`cleaner_source_${item.source}_${statsDate[0]}_${statsDate[1]}`"></SavingParam>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="overflow-x-auto">
          <v-btn
              @click="setFilters({candidate_status:'new', status: 3}, 'loadingBtn1')"
              :loading="loadingBtn1"
              :class="filters.candidate_status ? 'v-btn-info' : 'v-btn-transp'">Кандидаты
            <CBadge>{{ counts.status3 }}</CBadge>
          </v-btn>
          <v-btn
              @click="setFilters({status: 0,isNew:1}, 'loadingBtn2')"
              :loading="loadingBtn2"
              :class="filters.status==0 && filters.isNew ? 'v-btn-info' : 'v-btn-transp'">Стажеры
            <CBadge>{{ counts.isNew1 }}</CBadge>
            <CBadge color="danger" v-if="$store.state.updatingInfo.cleaners_red">
              {{ $store.state.updatingInfo.cleaners_red }}
            </CBadge>
          </v-btn>
          <v-btn
              @click="setFilters({status:0,isNew:0}, 'loadingBtn3')"
              :loading="loadingBtn3"
              :class="filters.status===0 && !filters.isNew ? 'v-btn-info' : 'v-btn-transp'">Оформлен
            <CBadge>{{ counts.isNew0 }}</CBadge>
            <CBadge color="warning" v-if="$store.state.updatingInfo.cleaners_orange">
              {{ $store.state.updatingInfo.cleaners_orange }}
            </CBadge>
            <CBadge color="purple" v-if="+$store.state.updatingInfo.cleanersWithoutVocations">
              {{ $store.state.updatingInfo.cleanersWithoutVocations }}
            </CBadge>
          </v-btn>
          <v-btn
              @click="setFilters({status:1}, 'loadingBtn4')"
              :loading="loadingBtn4"
              :class="filters.status===1 && !filters.isNew? 'v-btn-info' : 'v-btn-transp'">Не работают
            <CBadge>{{ counts.status1 }}</CBadge>

          </v-btn>
          <v-btn
              @click="setFilters({status:2}, 'loadingBtn5')"
              :loading="loadingBtn5"
              :class="filters.status===2 ? 'v-btn-info' : 'v-btn-transp'">Уволены
            <CBadge>{{ counts.status2 }}</CBadge>
          </v-btn>
          <v-btn
              v-if="counts.temp_block"
              @click="setFilters({temp_block:1})"
              :class="'v-btn-danger'">Заблокированы
            <CBadge>{{ counts.temp_block }}</CBadge>

          </v-btn>
          <v-btn class="d-none d-md-block"
                 @click="$eventBus.$emit('sendWorkerNotifications', {n_id: -1, name: 'Всем работающим клинерам'})">
            Отправить уведомления
          </v-btn>
          <v-text-field
              class="d-none d-md-block"
              style="margin-left: 20px"
              outlined
              v-model="search"
              placeholder="Поиск по таблице..."
              label=""
              append-icon="mdi-magnify"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions class="d-flex flex-column align-start d-md-none"
        >
          <v-btn @click="$eventBus.$emit('sendWorkerNotifications', {n_id: -1, name: 'Всем работающим клинерам'})"
                 class="mb-4">
            Отправить уведомления
          </v-btn>
          <v-text-field
              class="d-flex d-md-none"
              style="width: 100%"
              outlined
              v-model="search"
              placeholder="Поиск по таблице..."
              label=""
              append-icon="mdi-magnify"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions v-if="filters.status===3" class="mt-2 overflow-x-auto">
          <v-btn
              @click="setFilters({status:3, candidate_status:'new'}, 'loadingBtn6')"
              :class="filters.candidate_status==='new' ? 'v-btn-info' : 'v-btn-transp'">Новые
            <CBadge>{{ counts.status2_new }}</CBadge>
          </v-btn>
          <v-btn
              @click="setFilters({status:3, candidate_status: 'waiting'}, 'loadingBtn6')"
              :class="filters.candidate_status==='waiting' ? 'v-btn-info' : 'v-btn-transp'">Перезвонить
            <CBadge>{{ counts.status2_waiting }}</CBadge>
          </v-btn>
          <v-btn
              @click="setFilters({status:3, candidate_status:'interview'}, 'loadingBtn6')"
              :class="filters.candidate_status==='interview' ? 'v-btn-info' : 'v-btn-transp'">Собеседование
            <CBadge>{{ counts.status2_interview }}</CBadge>
          </v-btn>
          <v-btn
              @click="setFilters({status:3, candidate_status:'archive'}, 'loadingBtn6')"
              :class="filters.candidate_status==='archive' ? 'v-btn-info' : 'v-btn-transp'">Архив
            <CBadge>{{ counts.status2_archive }}</CBadge>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="filters.status === 2" class="py-4">
          <v-checkbox v-model="filters.isHired" label="Были оформлены" :true-value="1" :false-value="0"
          @change="setFilters()"/>
        </v-card-actions>
        <v-card-actions v-if="filters.status===2 || filters.status===3 && filters.candidate_status==='archive'">
          Дата добавления:
          <DatePeriod v-model="filters.period" @change="setFilters()"/>
          <v-text-field style="max-width: 200px" @change="setFilters()" placeholder="Телефон" outlined hide-details
                        v-model="filters.phone"/>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              :headers="getHeaders(filters)"
              :items="cleaners"
              item-key="n_id"
              @click:row="selectCleaner"
              :item-class="(item)=>{return item.salariesCount<2 && !(+item.isNew) && !(+item.status) ? 'green' : ''}"
              :loading="loadingCleaners"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Клинеров нет"
              class="stripped cursor-pointer"
              loading-text="Загрузка..."
              :search="search"
              mobile-breakpoint="0"
          >
            <template v-slot:item.index="{item, index}">
              <td style="white-space: nowrap">
                {{ index + 1 }}
                <v-icon v-if="!item.photo" color="red" size="16">mdi-camera</v-icon>
                <IconTip v-if="item.status==0 && +item.isNew && +item.ordersCountTotal>=3" size="20" color="red"
                         icon="check-decagram"
                         title="Стажер выполнил 3 уборки, надо перевести его в оформленные и добавить в чат"></IconTip>
                <IconTip v-if="item.status==0 && !+item.isNew && !+item.in_chat" size="20" color="red" icon="chat-alert"
                         title="Клинер не добавлен в чат"></IconTip>
                <IconTip color="red" icon="alert" v-if="item.warningNotWorking && item.status==0"
                         title="Клинер не был на заказах больше 7 дней, но не перенесен в «Не работают»"></IconTip>
                <IconTip v-if="item.vocationsNextWeek===0" color="purple" size="20" icon="calendar-remove"
                         title="Не проставлены выходные на след. неделю"></IconTip>
              </td>
            </template>
            <template v-slot:item.comment="{item, index}">
              <td style="max-width: 150px">
                {{ item.comment }}
                <div v-if="item.comment_fire && item.status==2">
                  <br><b>Причина увольнения:</b><br>
                  <p>{{ item.comment_fire }}</p>
                </div>
              </td>
            </template>
            <template v-slot:item.phone="{item, index}">
              <td v-on:click.stop><a :href="'tel: '+item.phone" style="white-space: nowrap">{{ item.phone }}</a></td>
            </template>
            <template v-slot:item.address="{item, index}">
              <td>
                <span :style="'color:#'+item.metroLineColor">{{ item.metro }}</span>
                {{ item.address }}
              </td>
            </template>
            <template v-slot:item.ratingStat.avg="{item, index}">
              <td :style="'color:'+ratingColor(item.ratingStats.avg)">{{
                  parseFloat(item.ratingStats.avg) || ''
                }}
              </td>
            </template>
            <template v-slot:item.ratingStatsTotal.likes="{item, index}">
              <td style="white-space: nowrap">
                <span style="color: green">{{ item.ratingStatsTotal.likes }}</span> / <span
                  style="color: red">{{ item.ratingStatsTotal.dislikes }}</span>
              </td>
            </template>
            <template v-slot:item.ordersCount="{item, index}">
              <td>
                <div style="display: flex;flex-direction: column;align-items: center;" v-if="+item.ordersCount">
                  <div>{{ item.ordersCount }}</div>
                  <div style="font-size: 12px;color: rgba(0,0,0,.6);">
                    {{ Object.values(item.ordersCountByType).join('/') }}
                  </div>
                </div>
              </td>
            </template>
            <template v-slot:item.interview_dt.str="{item, index}">
              <td>
                {{ $root.dateToRus(item.interview_dt.date) }} {{ item.interview_dt.time }}
              </td>
            </template>
            <template v-slot:item.source="{item, index}">
              <td>
                {{ item.source }}
              </td>
            </template>
            <template v-slot:item.datetime_createObj.date="{item, index}">
              <td>
                {{ $root.dateToRus(item.datetime_createObj.date) }}
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <Add ref="addCleaner"/>
      <NewCleaner ref="newCleaner"/>
      <InterviewSMS ref="InterviewSMS"/>
    </v-container>
  </v-app>
</template>
<script>
import Add from "@/views/cleaners/Add";
import NewCleaner from "@/views/cleaners/NewCleaner";
import InterviewSMS from "@/views/cleaners/InterviewSMS";
import DatePeriod from "../../components/DatePeriod";
import IconTip from "../../components/IconTip";
import YearMonth from "../../components/YearMonth";
import SavingParam from "../../components/SavingParam";
import globalMixins from "@/mixins/globalMixins";

export default {
  name: "Cleaners",
  mixins: [globalMixins],
  components: {
    SavingParam,
    YearMonth,
    IconTip,
    DatePeriod,
    Add,
    NewCleaner,
    InterviewSMS,
  },
  data() {
    return {
      loadingCleaners: true,
      headers: {
        new: [
          {text: '№', value: 'index',},
          {text: 'ФИО', value: 'name'},
          {text: 'Комментарий', value: 'comment'},
          {text: 'Телефон', value: 'phone'},
          {text: 'Источник', value: 'source'},
          {text: 'Добавлен', value: 'datetime_createObj.date'},
        ],
        interview: [
          {text: '№', value: 'index',},
          {text: 'ФИО', value: 'name'},
          {text: 'Комментарий', value: 'comment'},
          {text: 'Телефон', value: 'phone'},
          {text: 'Источник', value: 'source'},
          {text: 'Добавлен', value: 'datetime_createObj.date'},
          {text: 'Собеседование', value: 'interview_dt.str'},
        ],
        other: [
          {text: '№', value: 'index',},
          {text: 'ФИО', value: 'name'},
          {text: 'Комментарий', value: 'comment'},
          {text: 'Телефон', value: 'phone'},
          {text: 'Адрес', value: 'address'},
          {text: 'Кол-во уборок за месяц', value: 'ordersCount'},
          {text: 'Ср оценка за месяц', value: 'ratingStats.avg'},
          {text: 'Оценки', value: 'ratingStatsTotal.likes'},
          {text: 'Источник', value: 'source'},
          {text: 'Добавлен', value: 'datetime_createObj.date'},
        ]
      },
      filters: {
        status: 0,
        isNew: 0,
        isHired: 0,
        period: [this.$moment().format('YYYY-MM-01'), this.$moment().format('YYYY-MM-DD')],
      },
      counts: {},
      statsOpened: false,
      statsDate: [+this.$moment().format('YYYY'), this.$moment().format('MM')],
      stats: [],
      loadingBtn1: false,
      loadingBtn2: false,
      loadingBtn3: false,
      loadingBtn4: false,
      loadingBtn5: false,
      loadingBtn6: false,
      cleaners: [],
      search: '',
      status: '0',
      status2: '0',
      cleanerStatuses: [
        {text: 'Новый', value: '3'},
        {text: 'Работает', value: '0'},
        {text: 'Не работает', value: '1'},
        {text: 'Уволен', value: '2'},
      ],
    }
  },
  methods: {
    getHeaders(filters) {
      if (filters.status === 3) {
        return !['new', 'waiting'].includes(filters.candidate_status) ? this.headers.interview : this.headers.new;
      }
      return this.headers.other;
    },
    setFilters(params = null, buttonName = null) {
      let replaceFields = ['status', 'isNew', 'candidate_status', 'temp_block', 'isHired'];
      if (params !== null) replaceFields.forEach(field => this.filters[field] = params[field]);
      // for (let i in params) this.filters[i] = params[i];

      this.$root.saveData('cleaners', 'filters', this.filters);
      if (buttonName) this[buttonName] = true;
      this.reloadTable();
    },
    toggleStats(reloadOnly = false) {
      if (!reloadOnly) this.statsOpened = !this.statsOpened;
      if (this.statsOpened) {
        let date = this.$moment(`${this.statsDate[0]}-${this.statsDate[1]}-01`);
        date = [date.startOf('month').format('YYYY-MM-DD'), date.endOf('month').format('YYYY-MM-DD')]
        this.$store.state.server.request('cleaner/statsOform', {date}, (data) => {
          this.stats = data.response;
        })
      } else {
        this.stats = [];
      }
    },
    reloadTable(clearTable = true) {
      if (clearTable === true) {
        this.loadingCleaners = true;
        this.cleaners = [];
      }

      this.$store.state.server.request('cleaner/get', this.filters, (data) => {
        this.cleaners = data.response;
        this.counts = data.counts;
        this.loadingCleaners = false;
        this.loadingBtn1 = false;
        this.loadingBtn2 = false;
        this.loadingBtn3 = false;
        this.loadingBtn4 = false;
        this.loadingBtn5 = false;
        this.loadingBtn6 = false;
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/cleaners') this.reloadTable(false)
        }, 5000)
      }, () => {
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/cleaners') this.reloadTable(false)
        }, 5000)
        this.loadingCleaners = false
      });
    },
    selectCleaner(cleaner) {
      const newCleaner = this.$refs.newCleaner
      if (parseInt(cleaner.status) === 3) {
        newCleaner.open(cleaner.n_id)
      } /*else if (this.isLocalhost()) this.$router.push(`/cleaners/list/${cleaner.n_id}`)*/
      else this.$eventBus.$emit('openCleaner', cleaner.n_id)
    },
    addCleaner() {
      const modal = this.$refs.addCleaner
      modal.open()
    },
    newCleaner() {
      const modal = this.$refs.newCleaner
      modal.open()
    },
    ratingColor(rating) {
      rating = parseFloat(rating);
      if (rating >= 4.7) return 'green';
      else if (rating >= 4.2) return 'orange';
      else return 'red';
    }
  },
  mounted() {
    let filters = this.$root.getData('cleaners', 'filters');
    for (let i in filters) this.filters[i] = filters[i];
    this.reloadTable(false)
  },
  created() {
    this.$eventBus.$on('cleanerUpdated', () => this.reloadTable());
    this.$eventBus.$on('openCleanerCard', cleaner => this.selectCleaner(cleaner))
  },
  destroyed() {
    this.$eventBus.$off('cleanerUpdated');
    this.$eventBus.$off('openCleanerCard');
  }
}
</script>
