<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Отправка СМС о собеседовании</v-card-title>
      <v-card-subtitle><b>Первое</b> СМС отправляется в момент назначения собеседования, <b>второе</b> - в 19:00 за день
        до собеседования
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form"
                lazy-validation>
          <v-row>
            <v-subheader>Используйте в тексте смс:</v-subheader>
            <div style="width: 100%;color: #333333;">
              Дата: <span class="label">_date_</span>,
              Время: <span class="label">_time_</span>,
              Имя клинера: <span class="label">_name_</span>
            </div>
            <div style="color: #333333;">
              Например, "_name_, у вас завтра собеседование в _time_" - получится "Ирина, у вас завтра собеседование в
              14:30"
            </div>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>СМС в момент назначения собеседования</v-subheader>
            <v-textarea
                v-model="text"
                outlined/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Текст с подставленными значениями</v-subheader>
            <div style="color: #333333;border: 1px solid rgb(118, 118, 118);padding: 2px 10px;border-radius: 5px;"
                 v-html="renderedText(text)"></div>
          </v-row>
          <v-row v-if="text.length">
            Кол-во символов: {{ text.length }}, кол-во частей смс: {{ smsCount(text) }}шт. Стоимость:
            {{ 3 * smsCount(text) }}{{ $store.state.user.city.country.currency }} за 1 смс
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>СМС в 19:00 за день до собеседования</v-subheader>
            <v-textarea
                v-model="text2"
                outlined/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Текст с подставленными значениями</v-subheader>
            <div style="color: #333333;border: 1px solid rgb(118, 118, 118);padding: 2px 10px;border-radius: 5px;"
                 v-html="renderedText(text2)"></div>
          </v-row>
          <v-row v-if="text2.length">
            Кол-во символов: {{ text2.length }}, кол-во частей смс: {{ smsCount(text2) }}шт. Стоимость:
            {{ 3 * smsCount(text2) }}{{ $store.state.user.city.country.currency }} за 1 смс
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading_save" @click="set">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InterviewSMS",
  data() {
    return {
      opened: false,
      loading_save: false,
      text: '',
      text2: '',
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.get();
    },
    get() {
      this.$store.state.server.request('user/getParam', {name: 'interview_sms'}, (data) => {
        this.text = data.response || '';
      });
      this.$store.state.server.request('user/getParam', {name: 'interview_sms2'}, (data) => {
        this.text2 = data.response || '';
      });
    },
    set() {
      this.loading_save = true;
      this.$store.state.server.request('user/setParam', {name: 'interview_sms', value: this.text});
      this.$store.state.server.request('user/setParam', {name: 'interview_sms2', value: this.text2}, (data) => {
        this.loading_save = false
        this.opened = false
        this.$root.notify('Сохранено', 'success')
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading_save = false
      })
    },
    renderedText(text) {
      let params = {
        name: 'Ирина',
        time: '14:30',
        date: '21.09',
      };
      let rendered = text;
      for (let param in params) {
        rendered = rendered.split(`_${param}_`).join(params[param]);
      }
      rendered = rendered.split('\n').join('<br>');
      return rendered;
    },
    smsCount(text) {
      let rendered = this.renderedText(text);
      return rendered.length <= 70 ? 1 : Math.ceil(rendered.length / 67);
    },
  },
}
</script>

<style scoped>
span.label {
  background: #eee;
  padding: 2px 10px;
  border-radius: 5px;
}
</style>
